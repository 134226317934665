import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons

// React icons
import { FaFacebook, FaInstagram, FaGlobe } from "react-icons/fa"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"

import teamStyle from "assets/jss/material-kit-react/views/sponsorshipPageSections/teamStyle.jsx"

import harrisblu from "assets/img/harris-blu.jpeg"
import kicdigital from "assets/img/kic-digital.png"

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedSquare,
      classes.imgFluid
    )
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>Our Sponsors</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img
                    src={harrisblu}
                    alt="Harris Blu Sportswear"
                    className={imageClasses}
                    style={{ padding: "30px" }}
                  />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Harris Blu Sportswear
                  <br />
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Harris Blu was born out of a passion for working out, and
                    for feeling good whilst doing it - we love finding gorgeous,
                    unique activewear. Harris Blu Sportswear is a online store
                    that brings together the best, most exciting brands from all
                    over the globe, and puts them right here together in the UK.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <Button
                    href="https://instagram.com/harrisblusportswear"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <FaInstagram />
                  </Button>
                  <Button
                    href="https://facebook.com/harrisblusportswear"
                    target="_blank"
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaFacebook />
                  </Button>
                  <Button
                    href="https://harrisblusportswear.co.uk"
                    target="_blank"
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaGlobe />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img
                    src={kicdigital}
                    alt="KIC Digital"
                    className={imageClasses}
                  />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  KIC Digital
                  <br />
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    KIC Digital are a specialist website development company.
                    They offer a tailored and flexible approach to deliver
                    stylish websites which suit your company and customer’s needs.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <Button
                    href="https://instagram.com/kicdigital"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <FaInstagram />
                  </Button>
                  {/* <Button
                    href="https://facebook.com/harrisblusportswear"
                    target="_blank"
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaFacebook />
                  </Button> */}
                  {/* <Button
                    href="https://harrisblusportswear.co.uk"
                    target="_blank"
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaGlobe />
                  </Button> */}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    )
  }
}

export default withStyles(teamStyle)(TeamSection)
